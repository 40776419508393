export const SET_APP_MODE = 'SET_APP_MODE';
export const TOGGLE_PANEL = 'TOGGLE_PANEL';
export const OVERLAY_MENU_TOGGLE = 'OVERLAY_MENU_TOGGLE';
export const URL_CHANGE = 'URL_CHANGE';
export const SET_CURRENT_OPEN_SET = 'SET_CURRENT_OPEN_SET';

export const USER_CLICKED_RECORD = 'USER_CLICKED_RECORD';
export const USER_HOVERED_RECORD = 'USER_HOVERED_RECORD';
export const USER_TOUCHED_RECORD = 'USER_TOUCHED_RECORD';
export const RECORDSETS_TOGGLE_VIEW_FILTER = 'RECORDSETS_TOGGLE_VIEW_FILTER';

export const INIT_CACHE_RECORDSET = 'INIT_CACHE_RECORDSET';

export const AUTH_REQUEST_COMPLETE = 'AUTH_REQUEST_COMPLETE';
export const AUTH_REQUEST_ERROR = 'AUTH_REQUEST_ERROR';
export const AUTH_INITIALIZE_REQUEST = 'AUTH_INITIALIZE_REQUEST';
export const AUTH_INITIALIZE_COMPLETE = 'AUTH_INITIALIZE_COMPLETE';
export const AUTH_REINIT = 'AUTH_REINIT';
export const AUTH_SIGNIN_REQUEST = 'AUTH_SIGNIN_REQUEST';
export const AUTH_SIGNOUT_REQUEST = 'AUTH_SIGNOUT_REQUEST';
export const AUTH_SIGNOUT_COMPLETE = 'AUTH_SIGNOUT_COMPLETE';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_UPDATE_TOKEN_STATE = 'AUTH_UPDATE_TOKEN_STATE';
export const AUTH_REFRESH_ROLES_REQUEST = 'AUTH_REFRESH_ROLES_REQUEST';
export const AUTH_SET_DISRUPTED = 'AUTH_SET_DISRUPTED';
export const AUTH_SET_RECOVERED_FROM_DISRUPTION = 'AUTH_SET_RECOVERED_FROM_DISRUPTION';
export const AUTH_CLEAR_ROLES = 'AUTH_CLEAR_ROLES';
export const AUTH_REFRESH_ROLES_ERROR = 'AUTH_REFRESH_ROLES_ERROR';
export const AUTH_REFRESH_ROLES_COMPLETE = 'AUTH_REFRESH_ROLES_COMPLETE';
export const AUTH_SAVE_CURRENT_TO_OFFLINE = 'AUTH_SAVE_CURRENT_TO_OFFLINE';
export const AUTH_MAKE_OFFLINE_USER_CURRENT = 'AUTH_MAKE_OFFLINE_USER_CURRENT';
export const AUTH_FORGET_OFFLINE_USER = 'AUTH_FORGET_OFFLINE_USER';
export const AUTH_OPEN_OFFLINE_USER_SELECTION_DIALOG = 'AUTH_OPEN_OFFLINE_USER_SELECTION_DIALOG';

export const REFETCH_SERVER_BOUNDARIES = 'REFETCH_SERVER_BOUNDARIES';

export const OPEN_NEW_RECORD_MENU = 'OPEN_NEW_RECORD_MENU';
export const CLOSE_NEW_RECORD_MENU = 'CLOSE_NEW_RECORD_MENU';
export const USERINFO_LOAD_COMPLETE = 'USERINFO_LOAD_COMPLETE';
export const USERINFO_CLEAR_REQUEST = 'USERINFO_CLEAR_REQUEST';
export const TOGGLE_QUICK_PAN_TO_RECORD = 'TOGGLE_QUICK_PAN_TO_RECORD';

export const NETWORK_GO_ONLINE = 'NETWORK_GO_ONLINE';
export const NETWORK_GO_OFFLINE = 'NETWORK_GO_OFFLINE';

export const ACTIVITY_GET_INITIAL_STATE_REQUEST = 'ACTIVITY_GET_INITIAL_STATE_REQUEST';
export const ACTIVITY_GET_INITIAL_STATE_SUCCESS = 'ACTIVITY_GET_INITIAL_STATE_SUCCESS';
export const ACTIVITY_GET_INITIAL_STATE_FAILURE = 'ACTIVITY_GET_INITIAL_STATE_FAILURE';

export const ACTIVITY_GET_REQUEST = 'ACTIVITY_GET_REQUEST';
export const ACTIVITY_GET_NETWORK_REQUEST = 'ACTIVITY_GET_NETWORK_REQUEST';
export const ACTIVITY_GET_LOCAL_REQUEST = 'ACTIVITY_GET_LOCALDB_REQUEST';
export const ACTIVITY_GET_SUCCESS = 'ACTIVITY_GET_SUCCESS';
export const ACTIVITY_GET_FAILURE = 'ACTIVITY_GET_FAILURE';

export const ACTIVITY_UPDATE_GEO_REQUEST = 'ACTIVITY_UPDATE_GEO_REQUEST';
export const ACTIVITY_UPDATE_GEO_SUCCESS = 'ACTIVITY_UPDATE_GEO_SUCCESS';
export const ACTIVITY_UPDATE_GEO_FAILURE = 'ACTIVITY_UPDATE_GEO_FAILURE';
export const MAP_TOGGLE_TRACK_ME_DRAW_GEO_START = 'MAP_TOGGLE_TRACK_ME_DRAW_GEO_START';
export const MAP_TOGGLE_TRACK_ME_DRAW_GEO_STOP = 'MAP_TOGGLE_TRACK_ME_DRAW_GEO_STOP';
export const MAP_TOGGLE_TRACK_ME_DRAW_GEO_CLOSE = 'MAP_TOGGLE_TRACK_ME_DRAW_GEO_CLOSE';
export const MAP_TOGGLE_TRACK_ME_DRAW_GEO_PAUSE = 'MAP_TOGGLE_TRACK_ME_DRAW_GEO_PAUSE';
export const MAP_TOGGLE_TRACK_ME_DRAW_GEO_RESUME = 'MAP_TOGGLE_TRACK_ME_DRAW_GEO_RESUME';

export const ACTIVITY_BUILD_SCHEMA_FOR_FORM_REQUEST = 'ACTIVITY_BUILD_SCHEMA_FOR_FORM_REQUEST';
export const ACTIVITY_BUILD_SCHEMA_FOR_FORM_SUCCESS = 'ACTIVITY_BUILD_SCHEMA_FOR_FORM_SUCCESS';

export const ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST = 'ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST';
export const ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST_ONLINE =
  'ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST_ONLINE';
export const ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST_OFFLINE =
  'ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST_OFFLINE';
export const ACTIVITY_GET_SUGGESTED_JURISDICTIONS_SUCCESS = 'ACTIVITY_GET_SUGGESTED_JURISDICTIONS_SUCCESS';

export const ACTIVITY_GET_SUGGESTED_BIOCONTROL_REQUEST_ONLINE = 'ACTIVITY_GET_SUGGESTED_BIOCONTROL_REQUEST_ONLINE';
export const ACTIVITY_GET_SUGGESTED_BIOCONTROL_REQUEST_ONLINE_SUCCESS =
  'ACTIVITY_GET_SUGGESTED_BIOCONTROL_REQUEST_ONLINE_SUCCESS';
export const ACTIVITY_GET_SUGGESTED_BIOCONTROL_AGENTS = 'ACTIVITY_GET_SUGGESTED_BIOCONTROL_AGENTS';
export const ACTIVITY_GET_SUGGESTED_BIOCONTROL_AGENTS_SUCCESS = 'ACTIVITY_GET_SUGGESTED_BIOCONTROL_METHOD_AGENTS';

export const ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST = 'ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST';
export const ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST_ONLINE = 'ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST_ONLINE';
export const ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST_OFFLINE = 'ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST_OFFLINE';
export const ACTIVITY_GET_SUGGESTED_PERSONS_SUCCESS = 'ACTIVITY_GET_SUGGESTED_PERSONS_SUCCESS';

export const ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST = 'ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST';
export const ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST_ONLINE =
  'ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST_ONLINE';
export const ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST_OFFLINE =
  'ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST_OFFLINE';
export const ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_SUCCESS = 'ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_SUCCESS';

export const ACTIVITY_ON_FORM_CHANGE_REQUEST = 'ACTIVITY_ON_FORM_CHANGE_REQUEST';
export const ACTIVITY_ON_FORM_CHANGE_SUCCESS = 'ACTIVITY_ON_FORM_CHANGE_SUCCESS';

export const ACTIVITY_SET_CURRENT_HASH_REQUEST = 'ACTIVITY_SET_CURRENT_HASH_REQUEST';
export const ACTIVITY_SET_CURRENT_HASH_SUCCESS = 'ACTIVITY_SET_CURRENT_HASH_SUCCESS';
export const ACTIVITY_SET_CURRENT_HASH_FAILURE = 'ACTIVITY_SET_CURRENT_HASH_REQUEST';
export const ACTIVITY_SET_SAVED_HASH_REQUEST = 'ACTIVITY_SET_SAVED_HASH_REQUEST';
export const ACTIVITY_SET_SAVED_HASH_SUCCESS = 'ACTIVITY_SET_SAVED_HASH_SUCCESS';
export const ACTIVITY_SET_SAVED_HASH_FAILURE = 'ACTIVITY_SET_SAVED_HASH_REQUEST';

export const ACTIVITY_CHEM_TREATMENT_DETAILS_FORM_ON_CHANGE_REQUEST =
  'ACTIVITY_CHEM_TREATMENT_DETAILS_FORM_ON_CHANGE_REQUEST';
export const ACTIVITY_CHEM_TREATMENT_DETAILS_FORM_ON_CHANGE_SUCCESS =
  'ACTIVITY_CHEM_TREATMENT_DETAILS_FORM_ON_CHANGE_SUCCESS';

export const GET_API_DOC_REQUEST = 'GET_API_DOC_REQUEST';
export const GET_API_DOC_ONLINE = 'GET_API_DOC_ONLINE';
export const GET_API_DOC_SUCCESS = 'GET_API_DOC_SUCCESS';
export const GET_API_DOC_FAILURE = 'GET_API_DOC_FAILURE';

export const ACTIVITY_UPDATE_AUTOFILL_REQUEST = 'ACTIVITY_UPDATE_AUTOFILL_REQUEST';
export const ACTIVITY_UPDATE_AUTOFILL_SUCCESS = 'ACTIVITY_UPDATE_AUTOFILL_SUCCESS';
export const ACTIVITY_UPDATE_AUTOFILL_FAILURE = 'ACTIVITY_UPDATE_AUTOFILL_FAILURE';
export const ACTIVITY_UPDATE_PHOTO_REQUEST = 'ACTIVITY_UPDATE_PHOTO_REQUEST';
export const ACTIVITY_UPDATE_PHOTO_SUCCESS = 'ACTIVITY_UPDATE_PHOTO_SUCCESS';
export const ACTIVITY_UPDATE_PHOTO_FAILURE = 'ACTIVITY_UPDATE_PHOTO_FAILURE';
export const ACTIVITY_LINK_RECORD_REQUEST = 'ACTIVITY_LINK_RECORD_REQUEST';
export const ACTIVITY_LINK_RECORD_SUCCESS = 'ACTIVITY_LINK_RECORD_SUCCESS';
export const ACTIVITY_LINK_RECORD_FAILURE = 'ACTIVITY_LINK_RECORD_FAILURE';
export const ACTIVITY_PERSIST_REQUEST = 'ACTIVITY_PERSIST_REQUEST';
export const ACTIVITY_PERSIST_SUCCESS = 'ACTIVITY_PERSIST_SUCCESS';
export const ACTIVITY_PERSIST_FAILURE = 'ACTIVITY_PERSIST_FAILURE';

export const ACTIVITY_SAVE_REQUEST = 'ACTIVITY_SAVE_REQUEST';
export const ACTIVITY_SAVE_SUCCESS = 'ACTIVITY_SAVE_SUCCESS';

export const TOGGLE_CUSTOMIZE_LAYERS = 'TOGGLE_CUSTOMIZE_LAYERS';
export const ACTIVITY_PASTE_REQUEST = 'ACTIVITY_PASTE_REQUEST';
export const ACTIVITY_PASTE_SUCCESS = 'ACTIVITY_PASTE_SUCCESS';
export const ACTIVITY_PASTE_FAILURE = 'ACTIVITY_PASTE_FAILURE';

export const ACTIVITY_COPY_REQUEST = 'ACTIVITY_COPY_REQUEST';
export const ACTIVITY_COPY_SUCCESS = 'ACTIVITY_COPY_SUCCESS';
export const ACTIVITY_COPY_FAILURE = 'ACTIVITY_COPY_FAILURE';

export const ACTIVITY_SAVE_OFFLINE = 'ACTIVITY_SAVE_OFFLINE';
export const ACTIVITY_RESTORE_OFFLINE = 'ACTIVITY_RESTORE_OFFLINE';
export const ACTIVITY_OFFLINE_DELETE_ITEM = 'ACTIVITY_OFFLINE_DELETE_ITEM';
export const ACTIVITY_UPDATE_SYNC_STATE = 'ACTIVITY_UPDATE_SYNC_STATE';
export const ACTIVITY_OFFLINE_SYNC_DIALOG_SET_STATE = 'ACTIVITY_OFFLINE_SYNC_DIALOG_SET_STATE';

export const ACTIVITY_RUN_OFFLINE_SYNC = 'ACTIVITY_RUN_OFFLINE_SYNC';
export const ACTIVITY_RUN_OFFLINE_SYNC_COMPLETE = 'ACTIVITY_RUN_OFFLINE_SYNC_COMPLETE';

export const ACTIVITY_SAVE_NETWORK_REQUEST = 'ACTIVITY_SAVE_NETWORK_REQUEST';
export const ACTIVITY_SAVE_NETWORK_SUCCESS = 'ACTIVITY_SAVE_NETWORK_SUCCESS';
export const ACTIVITY_SAVE_NETWORK_FAILURE = 'ACTIVITY_SAVE_NETWORK_FAILURE';

export const ACTIVITY_CREATE_REQUEST = 'ACTIVITY_CREATE_REQUEST';
export const ACTIVITY_CREATE_NETWORK = 'ACTIVITY_CREATE_NETWORK';
export const ACTIVITY_CREATE_LOCAL = 'ACTIVITY_CREATE_LOCAL';
export const ACTIVITY_CREATE_SUCCESS = 'ACTIVITY_CREATE_SUCCESS';
export const ACTIVITY_CREATE_FAILURE = 'ACTIVITY_CREATE_FAILURE';

export const ACTIVITY_SUBMIT_REQUEST = 'ACTIVITY_SUBMIT_REQUEST';
export const ACTIVITY_SUBMIT_SUCCESS = 'ACTIVITY_SUBMIT_SUCCESS';
export const ACTIVITY_SUBMIT_FAILURE = 'ACTIVITY_SUBMIT_FAILURE';
export const ACTIVITY_DELETE_REQUEST = 'ACTIVITY_DELETE_REQUEST';
export const ACTIVITY_DELETE_NETWORK_REQUEST = 'ACTIVITY_DELETE_NETWORK_REQUEST';

export const PAN_AND_ZOOM_TO_ACTIVITY = 'PAN_AND_ZOOM_TO_ACTIVITY';
export const ACTIVITY_DELETE_SUCCESS = 'ACTIVITY_DELETE_SUCCESS';
export const ACTIVITY_DELETE_FAILURE = 'ACTIVITY_DELETE_FAILURE';
export const ACTIVITY_SET_ACTIVE_REQUEST = 'ACTIVITY_SET_ACTIVE_REQUEST';
export const ACTIVITY_SET_ACTIVE_SUCCESS = 'ACTIVITY_SET_ACTIVE_SUCCESS';
export const ACTIVITY_SET_ACTIVE_FAILURE = 'ACTIVITY_SET_ACTIVE_FAILURE';

export const ACTIVITY_ADD_PHOTO_REQUEST = 'ACTIVITY_ADD_PHOTO_REQUEST';
export const ACTIVITY_ADD_PHOTO_SUCCESS = 'ACTIVITY_ADD_PHOTO_SUCCESS';
export const ACTIVITY_ADD_PHOTO_FAILURE = 'ACTIVITY_ADD_PHOTO_FAILURE';
export const ACTIVITY_DELETE_PHOTO_REQUEST = 'ACTIVITY_DELETE_PHOTO_REQUEST';
export const ACTIVITY_DELETE_PHOTO_SUCCESS = 'ACTIVITY_DELETE_PHOTO_SUCCESS';
export const ACTIVITY_DELETE_PHOTO_FAILURE = 'ACTIVITY_DELETE_PHOTO_FAILURE';
export const ACTIVITY_EDIT_PHOTO_REQUEST = 'ACTIVITY_EDIT_PHOTO_REQUEST';
export const ACTIVITY_EDIT_PHOTO_SUCCESS = 'ACTIVITY_EDIT_PHOTO_SUCCESS';
export const ACTIVITY_EDIT_PHOTO_FAILURE = 'ACTIVITY_EDIT_PHOTO_FAILURE';

// NewRecordDialog.tsx selectors
export const USER_SETTINGS_SET_NEW_RECORD_DIALOG_STATE_REQUEST = 'USER_SETTINGS_SET_NEW_RECORD_DIALOG_STATE_REQUEST';
export const USER_SETTINGS_SET_NEW_RECORD_DIALOG_STATE_SUCCESS = 'USER_SETTINGS_SET_NEW_RECORD_DIALOG_STATE_SUCCESS';
export const USER_SETTINGS_SET_NEW_RECORD_DIALOG_STATE_FAILURE = 'USER_SETTINGS_SET_NEW_RECORD_DIALOG_STATE_FAILURE';

export const USER_SETTINGS_GET_INITIAL_STATE_REQUEST = 'USER_SETTINGS_GET_INITIAL_STATE_REQUEST';
export const USER_SETTINGS_GET_INITIAL_STATE_SUCCESS = 'USER_SETTINGS_GET_INITIAL_STATE_SUCCESS';
export const USER_SETTINGS_GET_INITIAL_STATE_FAILURE = 'USER_SETTINGS_GET_INITIAL_STATE_FAILURE';
export const USER_SETTINGS_SET_ACTIVE_ACTIVITY_REQUEST = 'USER_SETTINGS_SET_ACTIVE_ACTIVITY_REQUEST';
export const USER_SETTINGS_SET_ACTIVE_ACTIVITY_SUCCESS = 'USER_SETTINGS_SET_ACTIVE_ACTIVITY_SUCCESS';
export const USER_SETTINGS_SET_ACTIVE_ACTIVITY_FAILURE = 'USER_SETTINGS_SET_ACTIVE_ACTIVITY_FAILURE';
export const USER_SETTINGS_SET_ACTIVE_IAPP_REQUEST = 'USER_SETTINGS_SET_ACTIVE_IAPP_REQUEST';
export const USER_SETTINGS_SET_ACTIVE_IAPP_SUCCESS = 'USER_SETTINGS_SET_ACTIVE_IAPP_SUCCESS';
export const USER_SETTINGS_SET_ACTIVE_IAPP_FAILURE = 'USER_SETTINGS_SET_ACTIVE_IAPP_FAILURE';

export const WHATS_HERE_ID_CLICKED = 'WHATS_HERE_ID_CLICKED';

export const ACTIVITY_DEBUG = 'ACTIVITY_DEBUG';

export const RECORDSET_ADD_FILTER = 'RECORDSET_ADD_FILTER';
export const RECORDSET_REMOVE_FILTER = 'RECORDSET_REMOVE_FILTER';
export const RECORDSET_SET_SORT = 'RECORDSET_SET_SORT';
export const RECORDSET_UPDATE_FILTER = 'RECORDSET_UPDATE_FILTER';
export const RECORDSET_CLEAR_FILTERS = 'RECORDSET_CLEAR_FILTERS';
export const INIT_SERVER_BOUNDARIES_GET = 'INIT_SERVER_BOUNDARIES_GET';

export const USER_SETTINGS_ADD_RECORD_SET_REQUEST = 'USER_SETTINGS_ADD_RECORD_SET_REQUEST';
export const USER_SETTINGS_ADD_RECORD_SET_SUCCESS = 'USER_SETTINGS_ADD_RECORD_SET_SUCCESS';
export const USER_SETTINGS_ADD_RECORD_SET_FAILURE = 'USER_SETTINGS_ADD_RECORD_SET_FAILURE';

// wip simplification:
export const USER_SETTINGS_SET_RECORDSET = 'USER_SETTINGS_SET_RECORDSET';
export const USER_SETTINGS_ADD_RECORD_SET = 'USER_SETTINGS_ADD_RECORD_SET';
export const USER_SETTINGS_REMOVE_RECORD_SET = 'USER_SETTINGS_REMOVE_RECORD_SET';

export const USER_SETTINGS_SET_SELECTED_RECORD_REQUEST = 'USER_SETTINGS_SET_SELECTED_RECORD_REQUEST';
export const USER_SETTINGS_SET_SELECTED_RECORD_SUCCESS = 'USER_SETTINGS_SET_SELECTED_RECORD_SUCCESS';
export const USER_SETTINGS_SET_SELECTED_RECORD_FAILURE = 'USER_SETTINGS_SET_SELECTED_RECORD_FAILURE';
export const USER_SETTINGS_SET_BOUNDARIES_REQUEST = 'USER_SETTINGS_SET_BOUNDARIES_REQUEST';
export const USER_SETTINGS_SET_BOUNDARIES_SUCCESS = 'USER_SETTINGS_SET_BOUNDARIES_SUCCESS';
export const USER_SETTINGS_SET_BOUNDARIES_FAILURE = 'USER_SETTINGS_SET_BOUNDARIES_FAILURE';
export const USER_SETTINGS_DELETE_BOUNDARY_REQUEST = 'USER_SETTINGS_DELETE_BOUNDARY_REQUEST';
export const USER_SETTINGS_DELETE_BOUNDARY_SUCCESS = 'USER_SETTINGS_DELETE_BOUNDARY_SUCCESS';
export const USER_SETTINGS_DELETE_BOUNDARY_FAILURE = 'USER_SETTINGS_DELETE_BOUNDARY_FAILURE';
export const USER_SETTINGS_DELETE_KML_REQUEST = 'USER_SETTINGS_DELETE_KML_REQUEST';
export const USER_SETTINGS_DELETE_KML_SUCCESS = 'USER_SETTINGS_DELETE_KML_SUCCESS';
export const USER_SETTINGS_DELETE_KML_FAILURE = 'USER_SETTINGS_DELETE_KML_FAILURE';
export const USER_SETTINGS_ADD_BOUNDARY_TO_SET_REQUEST = 'USER_SETTINGS_ADD_BOUNDARY_TO_SET_REQUEST';
export const USER_SETTINGS_ADD_BOUNDARY_TO_SET_SUCCESS = 'USER_SETTINGS_ADD_BOUNDARY_TO_SET_SUCCESS';
export const USER_SETTINGS_ADD_BOUNDARY_TO_SET_FAILURE = 'USER_SETTINGS_ADD_BOUNDARY_TO_SET_FAILURE';
export const USER_SETTINGS_REMOVE_BOUNDARY_FROM_SET_REQUEST = 'USER_SETTINGS_REMOVE_BOUNDARY_FROM_SET_REQUEST';
export const USER_SETTINGS_REMOVE_BOUNDARY_FROM_SET_SUCCESS = 'USER_SETTINGS_REMOVE_BOUNDARY_FROM_SET_SUCCESS';
export const USER_SETTINGS_REMOVE_BOUNDARY_FROM_SET_FAILURE = 'USER_SETTINGS_REMOVE_BOUNDARY_FROM_SET_FAILURE';

export const USER_SETTINGS_TOGGLE_RECORDS_EXPANDED_REQUEST = 'USER_SETTINGS_TOGGLE_RECORDS_EXPANDED_REQUEST';
export const USER_SETTINGS_TOGGLE_RECORDS_EXPANDED_SUCCESS = 'USER_SETTINGS_TOGGLE_RECORDS_EXPANDED_SUCCESS';
export const USER_SETTINGS_TOGGLE_RECORDS_EXPANDED_FAILURE = 'USER_SETTINGS_TOGGLE_RECORDS_EXPANDED_FAILURE';
export const USER_SETTINGS_SET_DARK_THEME = 'USER_SETTINGS_SET_DARK_THEME';
export const USER_SETTINGS_SET_MAP_CENTER_REQUEST = 'USER_SETTINGS_SET_MAP_CENTER_REQUEST';
export const USER_SETTINGS_SET_MAP_CENTER_SUCCESS = 'USER_SETTINGS_SET_MAP_CENTER_SUCCESS';
export const USER_SETTINGS_SET_MAP_CENTER_FAILURE = 'USER_SETTINGS_SET_MAP_CENTER_FAILURE';

export const USER_SETTINGS_SET_API_ERROR_DIALOG = 'USER_SETTINGS_SET_API_ERROR_DIALOG';

// Tabs
export const TABS_GET_INITIAL_STATE_REQUEST = 'TABS_GET_INITIAL_STATE_REQUEST';
export const TABS_GET_INITIAL_STATE_SUCCESS = 'TABS_GET_INITIAL_STATE_SUCCESS';
export const TABS_GET_INITIAL_STATE_FAILURE = 'TABS_GET_INITIAL_STATE_FAILURE';
export const TABS_SET_ACTIVE_TAB_REQUEST = 'TABS_SET_ACTIVE_TAB_REQUEST';
export const TABS_SET_ACTIVE_TAB_SUCCESS = 'TABS_SET_ACTIVE_TAB_SUCCESS';
export const TABS_SET_ACTIVE_TAB_FAILURE = 'TABS_SET_ACTIVE_TAB_FAILURE';

export const ACTIVITIES_GEOJSON_GET_REQUEST = 'ACTIVITIES_GEOJSON_GET_REQUEST';
export const ACTIVITIES_GEOJSON_GET_ONLINE = 'ACTIVITIES_GEOJSON_GET_ONLINE';
export const ACTIVITIES_GEOJSON_GET_OFFLINE = 'ACTIVITIES_GEOJSON_GET_OFFLINE';
export const ACTIVITIES_GEOJSON_GET_SUCCESS = 'ACTIVITIES_GEOJSON_GET_SUCCESS';

export const ACTIVITIES_GEOJSON_REFETCH_ONLINE = 'ACTIVITIES_GEOJSON_REFETCH_ONLINE';

export const ACTIVITY_ERRORS = 'ACTIVITY_ERRORS';

export const IAPP_GET_REQUEST = 'IAPP_GET_REQUEST';
export const IAPP_GET_LOCALDB_REQUEST = 'IAPP_GET_LOCALDB';
export const IAPP_GET_NETWORK_REQUEST = 'IAPP_GET_ONLINE';
export const IAPP_GET_SUCCESS = 'IAPP_GET_SUCCESS';
export const IAPP_GET_FAILURE = 'IAPP_GET_FAILURE';

export const IAPP_PAN_AND_ZOOM = 'IAPP_PAN_AND_ZOOM';

export const IAPP_GEOJSON_GET_REQUEST = 'IAPP_GEOJSON_GET_REQUEST';
export const IAPP_GEOJSON_GET_ONLINE = 'IAPP_GEOJSON_GET_ONLINE';
export const IAPP_GEOJSON_GET_OFFLINE = 'IAPP_GEOJSON_GET_OFFLINE';
export const IAPP_GEOJSON_GET_SUCCESS = 'IAPP_GEOJSON_GET_SUCCESS';

export const IAPP_INIT_LAYER_STATE_REQUEST = 'IAPP_INIT_LAYER_STATE_REQUEST';
export const IAPP_INIT_LAYER_STATE_SUCCESS = 'IAPP_INIT_LAYER_STATE_SUCCESS';

export const IAPP_TABLE_ROWS_GET_REQUEST = 'IAPP_TABLE_ROWS_GET_REQUEST';
export const IAPP_TABLE_ROWS_GET_ONLINE = 'IAPP_TABLE_ROWS_GET_ONLINE';
export const IAPP_TABLE_ROWS_GET_OFFLINE = 'IAPP_TABLE_ROWS_GET_OFFLINE';
export const IAPP_TABLE_ROWS_GET_SUCCESS = 'IAPP_TABLE_ROWS_GET_SUCCESS';
export const IAPP_TABLE_ROWS_GET_FAILURE = 'IAPP_TABLE_ROWS_GET_FAILURE';

export const IAPP_RECORDSET_ID_LIST_GET_SUCCESS = 'IAPP_RECORDSET_ID_LIST_GET_SUCCESS';

export const ACTIVITIES_TABLE_ROWS_GET_REQUEST = 'ACTIVITIES_TABLE_ROWS_GET_REQUEST';
export const ACTIVITIES_TABLE_ROWS_GET_ONLINE = 'ACTIVITIES_TABLE_ROWS_GET_ONLINE';
export const ACTIVITIES_TABLE_ROWS_GET_OFFLINE = 'ACTIVITIES_TABLE_ROWS_GET_OFFLINE';
export const ACTIVITIES_TABLE_ROWS_GET_SUCCESS = 'ACTIVITIES_TABLE_ROWS_GET_SUCCESS';
export const ACTIVITIES_TABLE_ROWS_GET_FAILURE = 'ACTIVITIES_TABLE_ROWS_GET_FAILURE';

export const MAP_INIT_REQUEST = 'MAP_INIT_REQUEST';
export const MAP_INIT_FOR_RECORDSET = 'MAP_INIT_FOR_RECORDSET';
export const MAP_INIT_SUCCESS = 'MAP_INIT_SUCCESS';

export const MAP_TOGGLE_BASEMAP = 'MAP_TOGGLE_BASEMAP';
export const MAP_TOGGLE_HD = 'MAP_TOGGLE_HD';
export const MAP_TOGGLE_ACCURACY = 'MAP_TOGGLE_ACCURACY';
export const MAP_TOGGLE_TRACKING = 'MAP_TOGGLE_TRACKING';
export const MAP_TOGGLE_TRACKING_ON = 'MAP_TOGGLE_TRACKING_ON';
export const MAP_TOGGLE_TRACKING_OFF = 'MAP_TOGGLE_TRACKING_OFF';
export const MAP_TOGGLE_PANNED = 'MAP_TOGGLE_PANNED';
export const MAP_TOGGLE_LEGENDS = 'MAP_TOGGLE_LEGENDS';
export const MAP_SET_COORDS = 'MAP_SET_COORDS';
export const LEAFLET_SET_WHOS_EDITING = 'LEAFLET_SET_WHOS_EDITING';
export const TOGGLE_BASIC_PICKER_LAYER = 'TOGGLE_BASIC_PICKER_LAYER';
export const RECORD_SET_TO_EXCEL_REQUEST = 'RECORD_SET_TO_EXCEL_REQUEST';
export const RECORD_SET_TO_EXCEL_SUCCESS = 'RECORD_SET_TO_EXCEL_SUCCESS';
export const RECORD_SET_TO_EXCEL_FAILURE = 'RECORD_SET_TO_EXCEL_FAILURE';
export const MAP_LABEL_EXTENT_FILTER_REQUEST = 'MAP_LABEL_EXTENT_FILTER_REQUEST';
export const MAP_LABEL_EXTENT_FILTER_SUCCESS = 'MAP_LABEL_EXTENT_FILTER_SUCCESS';
export const MAP_LABEL_EXTENT_FILTER_FAILURE = 'MAP_LABEL_EXTENT_FILTER_FAILURE';
export const IAPP_EXTENT_FILTER_REQUEST = 'IAPP_EXTENT_FILTER_REQUEST';
export const IAPP_EXTENT_FILTER_SUCCESS = 'IAPP_EXTENT_FILTER_SUCCESS';
export const IAPP_EXTENT_FILTER_FAILURE = 'IAPP_EXTENT_FILTER_FAILURE';
export const SET_TOO_MANY_LABELS_DIALOG = 'SET_TOO_MANY_LABELS_DIALOG';

//whats here tool
export const MAP_TOGGLE_WHATS_HERE = 'MAP_TOGGLE_WHATS_HERE';
export const MAP_WHATS_HERE_FEATURE = 'MAP_WHATS_HERE_FEATURE';
export const MAP_WHATS_HERE_INIT_GET_POI = 'MAP_WHATS_HERE_INIT_GET_POI';
export const MAP_WHATS_HERE_INIT_GET_POI_IDS_FETCHED = 'MAP_WHATS_HERE_INIT_GET_POI_IDS_FETCHED';
export const MAP_WHATS_HERE_INIT_GET_ACTIVITY = 'MAP_WHATS_HERE_INIT_GET_ACTIVITY';
export const MAP_WHATS_HERE_INIT_GET_ACTIVITY_IDS_FETCHED = 'MAP_WHATS_HERE_INIT_GET_ACTIVITY_IDS_FETCHED';
export const WHATS_HERE_PAGE_POI = 'WHATS_HERE_PAGE_POI';
export const WHATS_HERE_SORT_FILTER_UPDATE = 'WHATS_HERE_SORT_FILTER_UPDATE';
export const MAP_SET_WHATS_HERE_PAGE_LIMIT = 'MAP_SET_WHATS_HERE_PAGE_LIMIT';
export const MAP_SET_WHATS_HERE_SECTION = 'MAP_SET_WHATS_HERE_SECTION';
export const MAP_WHATS_HERE_SET_HIGHLIGHTED_IAPP = 'MAP_WHATS_HERE_SET_HIGHLIGHTED_IAPP';
export const MAP_WHATS_HERE_SET_HIGHLIGHTED_ACTIVITY = 'MAP_WHATS_HERE_SET_HIGHLIGHTED_ACTIVITY';
export const TOGGLE_WMS_LAYER = 'TOGGLE_WMS_LAYER';
export const TOGGLE_LAYER_PICKER_OPEN = 'TOGGLE_LAYER_PICKER_OPEN';
export const TOGGLE_KML_LAYER = 'TOGGLE_KML_LAYER';
export const TOGGLE_DRAWN_LAYER = 'TOGGLE_DRAWN_LAYER';

export const MAP_ON_SHAPE_CREATE = 'MAP_ON_SHAPE_CREATE';
export const MAP_ON_SHAPE_UPDATE = 'MAP_ON_SHAPE_UPDATE';
export const MAIN_MAP_MOVE = 'MAIN_MAP_MOVE';
export const ACTIVITY_PAGE_MAP_EXTENT_TOGGLE = 'ACTIVITY_PAGE_MAP_EXTENT_TOGGLE';

export const WHATS_HERE_IAPP_ROWS_REQUEST = 'WHATS_HERE_IAPP_ROWS_REQUEST';
export const WHATS_HERE_IAPP_ROWS_ONLINE = 'WHATS_HERE_IAPP_ROWS_ONLINE';
export const WHATS_HERE_IAPP_ROWS_OFFLINE = 'WHATS_HERE_IAPP_ROWS_OFFLINE';
export const WHATS_HERE_IAPP_ROWS_SUCCESS = 'WHATS_HERE_IAPP_ROWS_SUCCESS';

export const WHATS_HERE_PAGE_ACTIVITY = 'WHATS_HERE_PAGE_ACTIVITY';
export const MAP_WHATS_HERE_INIT_GET_ACTIVITIES = 'MAP_WHATS_HERE_INIT_GET_ACTIVITIES';
export const WHATS_HERE_ACTIVITY_ROWS_REQUEST = 'WHATS_HERE_ACTIVITY_ROWS_REQUEST';
export const WHATS_HERE_ACTIVITY_ROWS_ONLINE = 'WHATS_HERE_ACTIVITY_ROWS_ONLINE';
export const WHATS_HERE_ACTIVITY_ROWS_OFFLINE = 'WHATS_HERE_ACTIVITY_ROWS_OFFLINE';
export const WHATS_HERE_ACTIVITY_ROWS_SUCCESS = 'WHATS_HERE_ACTIVITY_ROWS_SUCCESS';

export const MAP_DELETE_LAYER_AND_TABLE = 'MAP_DELETE_LAYER_AND_TABLE';

export const LAYER_STATE_UPDATE = 'LAYER_STATE_UPDATE';
export const FILTER_STATE_UPDATE = 'FILTER_STATE_UPDATE';
export const PAGE_OR_LIMIT_UPDATE = 'PAGE_OR_LIMIT_CHANGE';
export const SORT_COLUMN_STATE_UPDATE = 'SORT_COLUMN_STATE_UPDATE';

export const IAPP_GET_IDS_FOR_RECORDSET_REQUEST = 'IAPP_GET_IDS_FOR_RECORDSET_REQUEST';
export const IAPP_GET_IDS_FOR_RECORDSET_ONLINE = 'IAPP_GET_IDS_FOR_RECORDSET_ONLINE';
export const IAPP_GET_IDS_FOR_RECORDSET_OFFLINE = 'IAPP_GET_IDS_FOR_RECORDSET_OFFLINE';
export const IAPP_GET_IDS_FOR_RECORDSET_SUCCESS = 'IAPP_GET_IDS_FOR_RECORDSET_SUCCESS';

export const WHATS_HERE_SERVER_FILTERED_IDS_FETCHED = 'WHATS_HERE_SERVER_FILTERED_IDS_FETCHED';

export const ACTIVITIES_GET_IDS_FOR_RECORDSET_REQUEST = 'ACTIVITIES_GET_IDS_FOR_RECORDSET_REQUEST';
export const ACTIVITIES_GET_IDS_FOR_RECORDSET_ONLINE = 'ACTIVITIES_GET_IDS_FOR_RECORDSET_ONLINE';
export const ACTIVITIES_GET_IDS_FOR_RECORDSET_OFFLINE = 'ACTIVITIES_GET_IDS_FOR_RECORDSET_OFFLINE';
export const ACTIVITIES_GET_IDS_FOR_RECORDSET_SUCCESS = 'ACTIVITIES_GET_IDS_FOR_RECORDSET_SUCCESS';
export const FILTERS_PREPPED_FOR_VECTOR_ENDPOINT = 'FILTERS_PREPPED_FOR_VECTOR_ENDPOINT';
export const FILTER_PREP_FOR_VECTOR_ENDPOINT = 'FILTER_PREP_FOR_VECTOR_ENDPOINT';

export const MAP_TOGGLE_GEOJSON_CACHE = 'MAP_TOGGLE_GEOJSON_CACHE';

export const FILTER_FEATURE_SET_WITH_IDS = 'FILTER_FEATURE_SET_WITH_IDS';
export const MAP_MODE_SET = 'MAP_MODE_SET';

export const DRAW_CUSTOM_LAYER = 'DRAW_CUSTOM_LAYER';
export const CUSTOM_LAYER_DRAWN = 'CUSTOM_LAYER_DRAWN';
export const REMOVE_CLIENT_BOUNDARY = 'REMOVE_CLIENT_BOUNDARY';
export const REMOVE_SERVER_BOUNDARY = 'REMOVE_SERVER_BOUNDARY';

export const BATCH_LIST_REQUEST = 'BATCH_LIST_REQUEST';
export const BATCH_LIST_SUCCESS = 'BATCH_LIST_SUCCESS';
export const BATCH_LIST_ERROR = 'BATCH_LIST_ERROR';
export const BATCH_RETRIEVE_REQUEST = 'BATCH_RETRIEVE_REQUEST';
export const BATCH_RETRIEVE_SUCCESS = 'BATCH_RETRIEVE_SUCCESS';
export const BATCH_RETRIEVE_ERROR = 'BATCH_RETRIEVE_ERROR';
export const BATCH_CREATE_REQUEST = 'BATCH_CREATE_REQUEST';
export const BATCH_CREATE_REQUEST_WITH_CALLBACK = 'BATCH_CREATE_REQUEST_WITH_CALLBACK';
export const BATCH_CREATE_SUCCESS = 'BATCH_CREATE_SUCCESS';
export const BATCH_CREATE_ERROR = 'BATCH_CREATE_ERROR';
export const BATCH_UPDATE_REQUEST = 'BATCH_UPDATE_REQUEST';
export const BATCH_UPDATE_SUCCESS = 'BATCH_UPDATE_SUCCESS';
export const BATCH_UPDATE_ERROR = 'BATCH_UPDATE_ERROR';
export const BATCH_DELETE_REQUEST = 'BATCH_DELETE_REQUEST';
export const BATCH_DELETE_SUCCESS = 'BATCH_DELETE_SUCCESS';
export const BATCH_DELETE_ERROR = 'BATCH_DELETE_ERROR';
export const BATCH_EXECUTE_REQUEST = 'BATCH_EXECUTE_REQUEST';
export const BATCH_EXECUTE_SUCCESS = 'BATCH_EXECUTE_SUCCESS';
export const BATCH_EXECUTE_ERROR = 'BATCH_EXECUTE_ERROR';
export const BATCH_TEMPLATE_LIST_REQUEST = 'BATCH_TEMPLATE_LIST_REQUEST';
export const BATCH_TEMPLATE_LIST_SUCCESS = 'BATCH_TEMPLATE_LIST_SUCCESS';
export const BATCH_TEMPLATE_LIST_ERROR = 'BATCH_TEMPLATE_LIST_ERROR';
export const BATCH_TEMPLATE_DOWNLOAD_REQUEST = 'BATCH_TEMPLATE_DOWNLOAD_REQUEST';
export const BATCH_TEMPLATE_DOWNLOAD_SUCCESS = 'BATCH_TEMPLATE_DOWNLOAD_SUCCESS';
export const BATCH_TEMPLATE_DOWNLOAD_ERROR = 'BATCH_TEMPLATE_DOWNLOAD_ERROR';
export const BATCH_TEMPLATE_DOWNLOAD_CSV_REQUEST = 'BATCH_TEMPLATE_DOWNLOAD_CSV_REQUEST';
export const CSV_LINK_CLICKED = 'CSV_LINK_CLICKED';

export const EXPORT_CONFIG_LOAD_REQUEST = 'EXPORT_CONFIG_LOAD_REQUEST';
export const EXPORT_CONFIG_LOAD_ERROR = 'EXPORT_CONFIG_LOAD_ERROR';
export const EXPORT_CONFIG_LOAD_SUCCESS = 'EXPORT_CONFIG_LOAD_SUCCESS';

export const TRAINING_VIDEOS_LIST_REQUEST = 'TRAINING_VIDEOS_LIST_REQUEST';
export const TRAINING_VIDEOS_LIST_REQUEST_COMPLETE = 'TRAINING_VIDEOS_LIST_REQUEST_COMPLETE';

export const EMAIL_SETTINGS_UPDATE = 'EMAIL_SETTINGS_REQUEST';
export const EMAIL_SETTINGS_UPDATE_SUCCESS = 'EMAIL_SETTINGS_UPDATE_SUCCESS';
export const EMAIL_SETTINGS_UPDATE_FAILURE = 'EMAIL_SETTINGS_UPDATE_FAILURE';

export const EMAIL_SETTINGS_RETRIEVE_REQUEST = 'EMAIL_SETTINGS_RETRIEVE_REQUEST';
export const EMAIL_SETTINGS_RETRIEVE_REQUEST_SUCCESS = 'EMAIL_SETTINGS_RETRIEVE_REQUEST_SUCCESS';
export const EMAIL_SETTINGS_RETRIEVE_REQUEST_FAILURE = 'EMAIL_SETTINGS_RETRIEVE_REQUEST_FAILURE';

export const EMAIL_TEMPLATES_UPDATE = 'EMAIL_TEMPLATES_REQUEST';
export const EMAIL_TEMPLATES_UPDATE_SUCCESS = 'EMAIL_TEMPLATES_UPDATE_SUCCESS';
export const EMAIL_TEMPLATES_UPDATE_FAILURE = 'EMAIL_TEMPLATES_UPDATE_FAILURE';
export const EMAIL_TEMPLATES_SET_ACTIVE = 'EMAIL_TEMPLATES_SET_ACTIVE';

export const EMAIL_TEMPLATES_RETRIEVE_REQUEST = 'EMAIL_TEMPLATES_RETRIEVE_REQUEST';
export const EMAIL_TEMPLATES_RETRIEVE_REQUEST_SUCCESS = 'EMAIL_TEMPLATES_RETRIEVE_REQUEST_SUCCESS';
export const EMAIL_TEMPLATES_RETRIEVE_REQUEST_FAILURE = 'EMAIL_TEMPLATES_RETRIEVE_REQUEST_FAILURE';

export const CRASH_HANDLE_GLOBAL_ERROR = 'CRASH_HANDLE_GLOBAL_ERROR';

export const NEW_ALERT = 'NEW_ALERT';
export const NEW_PROMPT = 'NEW_PROMPT';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const CLEAR_PROMPTS = 'CLEAR_PROMPTS';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const CLEAR_PROMPT = 'CLEAR_PROMPT';
